import Image from "next/image";
import { useState } from "react";
import ErrorFreeIcon1 from "../../public/images/home/error-free-1.svg";
import ErrorFreeIcon2 from "../../public/images/home/error-free-2.svg";
import ErrorFreeIcon3 from "../../public/images/home/error-free-3.svg";
import ErrorFreeApp from "../../public/images/home/error-free-ipad.png";

const ErrorFree = () => {
  const [activeFeature, setActiveFeature] = useState(1);
  const featureMap = [
    {
      icon: ErrorFreeIcon1,
      label1: "Accurate measurements",
      label2: "turn green",
    },
    {
      icon: ErrorFreeIcon2,
      label1: "Arranging fittings ensure",
      label2: "you got them all",
    },
    {
      icon: ErrorFreeIcon3,
      label1: "Input errors become",
      label2: "a thing of the past",
    },
  ];

  return (
    <div className="bg-full-bleed-dark-green py-36">
      <div className="flex justify-between gap-5">
        <div>
          <div className="flex items-center gap-5">
            <div className="flex gap-2 items-center font-semibold text-[#C4CAD4]">
              <div>Easier</div>
              <div>|</div>
              <div>Faster</div>
              <div>|</div>
              <div className="font-bold text-black">Error Free</div>
            </div>
          </div>
          <div className="flex flex-col gap-3 my-16 text-5xl font-extrabold">
            <span>Catches Errors</span>
            <span>and Omissions</span>
          </div>
          <div className="mb-20 font-semibold">
            Human errors are inevitable on big jobs. And they are costly. With
            EasyDuct, each fittings ensures correct measurements. Once correct,
            they turn green. Making this the first tool where you use colors and
            the drawing canvas to visually check for accuracy and doneness!
          </div>
        </div>
        <div className="w-[636px] h-[490px]">
          <Image
            src={ErrorFreeApp}
            width={636}
            height={490}
            alt=""
            layout="fixed"
          />
        </div>
      </div>
      <div className="flex gap-10 mt-20">
        {featureMap.map((feature, index) => (
          <div
            key={index}
            // className={`w-[33%] border-b-4 pb-16 cursor-pointer ${
            //   activeFeature === index + 1
            //     ? "border-[#00E09E]"
            //     : "border-[#F5F5F5]"
            // }`}
            // onClick={() => setActiveFeature(index + 1)}
            className="w-[33%] border-b-4 pb-16 border-[#8BCCCC]"
          >
            <div className="flex items-center gap-10">
              <div className="bg-[#8BCCCC] flex justify-center rounded-[2rem] w-[110px] h-[100px]">
                <Image
                  src={feature.icon}
                  width={55}
                  height={55}
                  className="w-[55px] h-[auto]"
                  alt=""
                />
              </div>
              <div className="font-bold">
                {feature.label1}
                <br />
                {feature.label2}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ErrorFree;
