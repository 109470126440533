import { faArrowRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSession } from "next-auth/react";
import Head from "next/head";
import Image from "next/image";
import { useState } from "react";
import ContactUs from "../components/homepage/ContactUs";
import Easier from "../components/homepage/Easier";
import ErrorFree from "../components/homepage/ErrorFree";
import Faster from "../components/homepage/Faster";
import Footer from "../components/homepage/Footer";
import Header from "../components/homepage/Header";
import TryItNow from "../components/modal/TryItNow";
import SectionBg from "../public/images/bg-center.png";
import Check from "../public/images/home/check.svg";
import Dots from "../public/images/home/dots.png";
import EasyDuctApp from "../public/images/home/easyduct-app-main.png";
import useInstance from "../state/useInstance";

export default function Home() {
  const [open, setOpen] = useState(false);
  const { status } = useSession();
  const isLoggedIn = status === "authenticated" || status === "loading";
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div id="easyduct-home">
      <Header />
      <Head>
        <title>EasyDuct App</title>
        <meta name="description" content="" />
      </Head>
      <div
        className="absolute w-full h-[1219px] top-0 -z-10"
        style={{
          backgroundColor: "#fff",
          backgroundImage: `url("${SectionBg.src}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "1219",
        }}
      >
        <div className="relative boxed-container">
          <div className="mt-48 text-center">
            <div className="flex flex-col gap-1 text-6xl font-extrabold">
              <span>{"Get'er Done"}</span>
              <span>in Realtime with</span>
            </div>
            <div className="text-6xl leading-normal font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#8DF924] to-[#00B4FF]">
              EasyDuct App
            </div>
            <div className="mt-10 font-semibold">
              No more complex tools that are hard to learn and use. Easier than
              a {"kids'"} coloring book for takeoffs. <br />
              100% error-free for ordering.
            </div>
            <div className="flex justify-center gap-16 mt-16">
              {!isLoggedIn && (
                <div
                  className="flex gap-3 items-center bg-[#00E09E] text-white px-12 py-4 font-semibold rounded-lg cursor-pointer hover:bg-gray-600"
                  onClick={onOpenModal}
                >
                  Try it Now
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              )}
              <div
                className="flex items-center gap-3 px-10 py-4 font-semibold text-black border-2 border-gray-600 rounded-lg cursor-pointer hover:bg-white"
                onClick={onOpenModal}
              >
                <FontAwesomeIcon className="text-[#00E09E]" icon={faPlay} />
                Watch Demo
              </div>
            </div>
            <div className="flex justify-between gap-20 w-[65%] mx-[auto] font-semibold mt-16 mb-3">
              <div className="flex items-center gap-3">
                <Image src={Check} width={30} height={30} alt="" />
                <span>Nothing to Learn</span>
              </div>
              <div className="flex items-center gap-3">
                <Image src={Check} width={30} height={30} alt="" />
                <span>Nothing to Pay</span>
              </div>
              <div className="flex items-center gap-3">
                <Image src={Check} width={30} height={30} alt="" />
                <span>Nothing to Lose</span>
              </div>
            </div>
            <div className="relative flex flex-col items-center">
              <Image
                className="z-20"
                src={EasyDuctApp}
                width={832}
                height={618}
                alt=""
              />
              <div className="absolute top-[70%]">
                <Image
                  className="absolute z-0"
                  src={Dots}
                  width={1030}
                  height={250}
                  alt=""
                />
              </div>
            </div>
          </div>
          <Easier />
          <Faster />
          <ErrorFree />
          <ContactUs />
          <Footer />
          <TryItNow isOpen={open} onCloseFn={onCloseModal} />
        </div>
      </div>
    </div>
  );
}

Home.publicPage = true;
