import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import { useState } from "react";
import TryItNow from "../../components/modal/TryItNow";
import EasierIcon1 from "../../public/images/home/easier-1.svg";
import EasierIcon2 from "../../public/images/home/easier-2.svg";
import EasierIcon3 from "../../public/images/home/easier-3.svg";
import EasyDuctApp from "../../public/images/home/easy-duct-app-1.png";

const Faster = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [activeFeature, setActiveFeature] = useState(1);
  const featureMap = [
    { icon: EasierIcon3, label1: "Use an iPad at", label2: "the site" },
    {
      icon: EasierIcon2,
      label1: "Input precise",
      label2: "measurements",
    },
    { icon: EasierIcon1, label1: "Order", label2: "instantly" },
  ];

  return (
    <div className="bg-full-bleed-green py-36">
      <div className="flex justify-between gap-5">
        <div>
          <div className="flex items-center gap-5">
            <div className="flex gap-2 items-center font-semibold text-[#C4CAD4]">
              <div>Easier</div>
              <div>|</div>
              <div className="font-bold text-black">Faster</div>
              <div>|</div>
              <div>Error Free</div>
            </div>
          </div>
          <div className="flex flex-col gap-3 my-16 text-5xl font-extrabold">
            <span>Goes With you</span>
            <span>to the Job Site </span>
          </div>
          <div className="mb-20 font-semibold">
            EasyDuct App is optimized for an iPad so you can take it wherever
            you go. Save tons of time by updating an estimate with actual
            measurements on-site. Draw a run to make sure you have everything.
            And send your order in real time instead of waiting to get back to
            the office.
          </div>
          <div
            className="flex gap-3 items-center bg-[#282D35] text-white px-10 py-4 font-semibold rounded-xl cursor-pointer hover:bg-gray-600 max-w-max"
            onClick={onOpenModal}
          >
            Try it Now
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
        <div className="w-[636px] h-[490px]">
          <Image
            src={EasyDuctApp}
            width={636}
            height={490}
            alt=""
            layout="fixed"
          />
        </div>
      </div>
      <div className="flex gap-10 mt-20">
        {featureMap.map((feature, index) => (
          <div
            key={index}
            // className={`w-[33%] border-b-4 pb-16 cursor-pointer ${
            //   activeFeature === index + 1
            //     ? "border-[#00E09E]"
            //     : "border-[#F5F5F5]"
            // }`}
            // onClick={() => setActiveFeature(index + 1)}
            className="w-[33%] border-b-4 pb-16 border-[#00E09E]"
          >
            <div className="flex items-center gap-10">
              <div className="bg-[#00E09E] flex justify-center rounded-[2rem] w-[110px] h-[100px]">
                <Image
                  src={feature.icon}
                  width={55}
                  height={55}
                  className="w-[55px] h-[auto]"
                  alt=""
                />
              </div>
              <div className="font-bold">
                {feature.label1}
                <br />
                {feature.label2}
              </div>
            </div>
          </div>
        ))}
      </div>
      <TryItNow isOpen={open} onCloseFn={onCloseModal} />
    </div>
  );
};

export default Faster;
