import Image from "next/image";
import { useState } from "react";
import DragDrop from "../../public/images/home/dragdrop.svg";
import TryItNow from "../../public/images/home/easy-duct-app.png";
import Plane from "../../public/images/home/plane.svg";
import Robot from "../../public/images/home/robot.svg";

const Easier = () => {
  const [activeFeature, setActiveFeature] = useState(1);
  const featureMap = [
    { icon: DragDrop, label1: "Drag, Drop and", label2: "Rotate" },
    {
      icon: Robot,
      label1: "Make transformers",
      label2: "when you are bored",
    },
    { icon: Plane, label1: "Send It to", label2: "Others" },
  ];

  return (
    <div className="mt-20 pb-36">
      <div className="flex justify-between gap-10">
        <div>
          <div className="flex gap-2 items-center mt-16 font-semibold text-[#C4CAD4]">
            <div className="font-bold text-black">Easier</div>
            <div>|</div>
            <div>Faster</div>
            <div>|</div>
            <div>Error Free</div>
          </div>
          <div className="flex flex-col gap-3 my-16 text-5xl font-extrabold">
            <span>{"It's"} Easier Than A</span>
            <span>Coloring Book</span>
          </div>
          <div className="font-semibold">
            The first tool allows you to just choose a fitting and drag it with
            a mouse. Have fun shaping and rotating anyway you want. Double-click
            to add actual measurements for orders and guestimates for takeoffs.
          </div>
        </div>
        <div className="w-[636px] h-[490px]">
          <Image
            src={TryItNow}
            width={636}
            height={490}
            alt=""
            layout="fixed"
          />
        </div>
      </div>
      <div className="flex gap-10 mt-20">
        {featureMap.map((feature, index) => (
          <div
            key={index}
            // className={`w-[33%] border-b-4 pb-16 cursor-pointer ${
            //   activeFeature === index + 1
            //     ? "border-[#00E09E]"
            //     : "border-[#F5F5F5]"
            // }`}
            // onClick={() => setActiveFeature(index + 1)}
            className="w-[33%] border-b-4 pb-16 border-[#E5E5E5]"
          >
            <div className="flex items-center gap-10">
              <div className="bg-[#F5F5F5] flex justify-center rounded-[2rem] w-[110px] h-[100px]">
                <Image
                  src={feature.icon}
                  width={55}
                  height={55}
                  className="w-[55px] h-[auto]"
                  alt=""
                />
              </div>
              <div className="font-bold">
                {feature.label1}
                <br />
                {feature.label2}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Easier;
